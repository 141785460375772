<template>
  <div class="stl-preview">
    <header class="header">
      <h1>STL文件在线预览工具</h1>
      <div class="file-input">
        <label for="file-upload" class="custom-file-upload">
          <i class="fas fa-upload"></i> 选择STL文件
        </label>
        <input id="file-upload" type="file" @change="handleFileUpload" accept=".stl" />
      </div>
      <div class="color-picker">
        <label for="color-input">模型颜色：</label>
        <input type="color" id="color-input" v-model="modelColor" @change="updateModelColor">
      </div>
    </header>
    <p class="description">
      使用我们的STL文件在线预览工具，您可以轻松上传和查看3D模型。支持颜色自定义，操作简单直观。
    </p>
    <div v-if="loading" class="progress-container">
      <div class="progress-bar">
        <div class="progress-fill" :style="{ width: `${progress}%` }"></div>
      </div>
      <span class="progress-text">{{ progressText }}</span>
    </div>
    <div ref="container" class="preview-container"></div>
  </div>
</template>

<script>
import * as THREE from 'three';
import { STLLoader } from 'three/examples/jsm/loaders/STLLoader';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { onMounted, ref, shallowRef } from 'vue';

export default {
  name: 'STLPreview',
  setup() {
    const container = ref(null);
    const scene = shallowRef(null);
    const camera = shallowRef(null);
    const renderer = shallowRef(null);
    const controls = shallowRef(null);
    const mesh = shallowRef(null);
    const loading = ref(false);
    const progress = ref(0);
    const progressText = ref('');
    // eslint-disable-next-line no-unused-vars
    const modelColor = ref('#FFFFF0'); // 设置为象牙白
    const group = shallowRef(null);

    onMounted(() => {
      initThreeJS();
    });

    function initThreeJS() {
      scene.value = new THREE.Scene();
      // 将背景颜色改为深蓝色
      scene.value.background = new THREE.Color(0x0a192f);

      const aspect = container.value.clientWidth / container.value.clientHeight;
      camera.value = new THREE.PerspectiveCamera(75, aspect, 0.1, 1000);
      camera.value.position.set(0, 0, 100);

      renderer.value = new THREE.WebGLRenderer({ antialias: true });
      renderer.value.setSize(container.value.clientWidth, container.value.clientHeight);
      container.value.appendChild(renderer.value.domElement);

      controls.value = new OrbitControls(camera.value, renderer.value.domElement);
      controls.value.enableDamping = true;
      controls.value.dampingFactor = 0.25;
      controls.value.screenSpacePanning = false;
      controls.value.maxDistance = 500;

      const light = new THREE.DirectionalLight(0xffffff, 0.8);
      light.position.set(1, 1, 1).normalize();
      scene.value.add(light);

      const ambientLight = new THREE.AmbientLight(0x404040, 0.2);
      scene.value.add(ambientLight);

      window.addEventListener('resize', onWindowResize);

      animate();
    }

    function onWindowResize() {
      const aspect = container.value.clientWidth / container.value.clientHeight;
      camera.value.aspect = aspect;
      camera.value.updateProjectionMatrix();
      renderer.value.setSize(container.value.clientWidth, container.value.clientHeight);
    }

    function animate() {
      requestAnimationFrame(animate);
      controls.value.update();
      renderer.value.render(scene.value, camera.value);
    }

    function handleFileUpload(event) {
      const file = event.target.files[0];
      if (file) {
        // 在加载新模型之前，删除旧模型
        if (group.value) {
          scene.value.remove(group.value);
          group.value = null;
        }

        loading.value = true;
        progress.value = 0;
        progressText.value = '正在读取文件...';

        const reader = new FileReader();
        reader.onprogress = (e) => {
          if (e.lengthComputable) {
            progress.value = (e.loaded / e.total) * 50;
            progressText.value = `正在读取文件... ${Math.round(progress.value)}%`;
          }
        };
        reader.onload = (e) => {
          progress.value = 50;
          progressText.value = '正在解析STL文件...';
          const contents = e.target.result;
          const loader = new STLLoader();
          const geometry = loader.parse(contents);
          progress.value = 75;
          progressText.value = '正在创建3D模型...';
          createMesh(geometry);
          loading.value = false;
        };
        reader.readAsArrayBuffer(file);
      }
    }

    function createMesh(geometry) {
      const material = new THREE.MeshPhongMaterial({ 
        color: modelColor.value,
        shininess: 80, // 稍微降低光泽度，使其看起来更自然
        specular: 0x222222 // 调整镜面反射
      });
      mesh.value = new THREE.Mesh(geometry, material);

      geometry.computeBoundingBox();
      const boundingBox = geometry.boundingBox;
      const center = new THREE.Vector3();
      boundingBox.getCenter(center);

      // 创建一个新的组来包含网格
      group.value = new THREE.Group();
      group.value.add(mesh.value);
      
      // 将网格移动到组的中心
      mesh.value.position.sub(center);

      const size = new THREE.Vector3();
      boundingBox.getSize(size);
      const maxDim = Math.max(size.x, size.y, size.z);
      
      // 添加新的组到场景中
      scene.value.add(group.value);

      // 调整相机位置以确保模型完全可见
      const aspect = container.value.clientWidth / container.value.clientHeight;
      const fov = 45; // 视野角度
      let distance = maxDim / (2 * Math.tan((fov / 2) * Math.PI / 180));
      
      // 根据宽高比调整距离，确保模型在水平和垂直方向都完全可见
      if (aspect > 1) {
        distance = Math.max(distance, (maxDim * aspect) / (2 * Math.tan((fov / 2) * Math.PI / 180)));
      } else {
        distance = Math.max(distance, maxDim / (2 * aspect * Math.tan((fov / 2) * Math.PI / 180)));
      }

      camera.value.fov = fov;
      camera.value.aspect = aspect;
      camera.value.near = 0.1;
      camera.value.far = distance * 4;
      camera.value.position.set(0, 0, distance * 1.1); // 稍微增加一点距离，确保完全可见
      camera.value.lookAt(0, 0, 0);
      camera.value.updateProjectionMatrix();

      // 重置控制器
      controls.value.target.set(0, 0, 0);
      controls.value.update();

      // 更新渲染器大小
      renderer.value.setSize(container.value.clientWidth, container.value.clientHeight);

      progress.value = 100;
      progressText.value = '加载完成';
      setTimeout(() => {
        loading.value = false;
      }, 500);
    }

    function updateModelColor() {
      if (mesh.value) {
        mesh.value.material.color.setStyle(modelColor.value);
      }
    }

    return {
      container,
      handleFileUpload,
      loading,
      progress,
      progressText,
      modelColor,
      updateModelColor
    };
  }
};
</script>

<style scoped>
.stl-preview {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: #0a192f;
  font-family: 'Roboto', sans-serif;
  color: #64ffda;
}

.header {
  background-color: #112240;
  padding: 10px 20px; /* 减少上下内边距 */
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

h1 {
  margin: 0;
  font-size: 24px; /* 稍微减小字体大小 */
  font-weight: 300;
  letter-spacing: 2px;
  text-transform: uppercase;
}

.file-input {
  margin: 10px 0; /* 减少上下外边距 */
}

.custom-file-upload {
  border: 2px solid #64ffda;
  display: inline-block;
  padding: 8px 16px; /* 减少内边距 */
  cursor: pointer;
  background-color: transparent;
  color: #64ffda;
  border-radius: 30px;
  transition: all 0.3s ease;
  font-size: 14px; /* 稍微减小字体大小 */
}

.custom-file-upload:hover {
  background-color: #64ffda;
  color: #0a192f;
}

.custom-file-upload i {
  margin-right: 8px;
}

input[type="file"] {
  display: none;
}

.preview-container {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  /* 将背景颜色改为深蓝色，与 Three.js 场景背景颜色一致 */
  background-color: #0a192f;
  border-radius: 8px;
  /* 调整阴影颜色，使其更适合深色背景 */
  box-shadow: inset 0 0 30px rgba(100, 255, 218, 0.3);
  overflow: hidden;
}

.progress-container {
  width: 100%;
  padding: 20px;
  background-color: #112240;
  text-align: center;
}

.progress-bar {
  width: 80%;
  height: 10px;
  background-color: #1d2d50;
  border-radius: 5px;
  margin: 0 auto 10px;
  overflow: hidden;
}

.progress-fill {
  height: 100%;
  background-color: #64ffda;
  transition: width 0.3s ease;
}

.progress-text {
  font-size: 14px;
  color: #64ffda;
}

@media (max-width: 600px) {
  .header {
    padding: 8px 15px; /* 进一步减少移动设备上的内边距 */
  }

  h1 {
    font-size: 20px;
  }

  .custom-file-upload {
    padding: 6px 12px;
    font-size: 12px;
  }
}

/* 添加一些科技感的动画效果 */
@keyframes glow {
  0% {
    box-shadow: 0 0 5px #64ffda;
  }
  50% {
    box-shadow: 0 0 20px #64ffda;
  }
  100% {
    box-shadow: 0 0 5px #64ffda;
  }
}

.custom-file-upload {
  animation: glow 2s infinite;
}

.color-picker {
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.1);
  padding: 5px;
  border-radius: 15px;
}

.color-picker label {
  margin-right: 10px;
  font-size: 14px;
  color: #FFFFF0; /* 更新为象牙白 */
}

input[type="color"] {
  -webkit-appearance: none;
  border: 2px solid #FFFFF0; /* 更新为象牙白 */
  width: 32px;
  height: 32px;
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;
  background-color: transparent;
}

input[type="color"]::-webkit-color-swatch-wrapper {
  padding: 0;
}

input[type="color"]::-webkit-color-swatch {
  border: none;
  border-radius: 50%;
}

@media (max-width: 600px) {
  .color-picker {
    flex-direction: column;
    align-items: flex-start;
  }

  .color-picker label {
    margin-bottom: 5px;
  }
}

.description {
  text-align: center;
  padding: 10px 20px;
  color: #a8b2d1;
  font-size: 14px;
  max-width: 800px;
  margin: 0 auto;
}
</style>